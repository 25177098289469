/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../store/actions';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import { StoreService } from '../services';
import { toAmount } from '../utils/parse';

const ADMIN_ID = 1;

const Checkout = ({ onClose }) => {
  const { cart, dispatchTypes } = useSelector(state => state);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  let total = 0;
  for (const product of cart) {
    total = toAmount(total + product.sale_price * product.quantity);
  }

  const [form, setForm] = useState({
    name: '',
    code: '+58',
    tlf: '',
    dispatch: null,
    errorName: false,
    errorPhone: false,
    errorDispatch: false,
    note: '',
  })

  const [showCodes, setShowCodes] = useState(false);

  const onSubmit = () => {
    if (!cart.length) {
      toast.error('Debe agregar algún producto al carrito');
      return onClose();
    }

    if (!form.name) {
      toast.error('Debe indicar su nombre');
      return setForm({ ...form, errorName: true });
    }

    if (form.tlf.length < 10) {
      toast.error('Verifique el número de teléfono');
      return setForm({ ...form, errorPhone: true });
    }

    const _dispatchType = dispatchTypes.find(x => x.value === form.dispatch);
    const dispatchType = _dispatchType?.label;

    if (!dispatchType) {
      toast.error('Debe seleccionar la forma de despacho');
      return setForm({ ...form, errorDispatch: true });
    }

    setLoading(true);

    StoreService.confirm({
      ...form,
      admin_id: ADMIN_ID,
      products: cart.map(({ id, quantity }) => ({ id, quantity })),
    })
      .then(async res => {
        if (res.data?.error) return toast.error(res.data.error);

        let total = 0;
        for (const product of res.data.message) {
          total = toAmount(total + product.precio * product.cantidad);
        }

        let message = `Nombre: ${form.name}\n`;
        message += `Teléfono: ${form.code}${form.tlf}\n`;
        message += `Nota: ${form.note}\n`;
        message += `Forma de despacho: ${dispatchType}\n\n`;
        message += res.data.message.map(x => `${x.cantidad}x ${x.producto}, *${ '$' + toAmount(x.precio) }*`).join('\n');
        message += `\n\n*Total: $${total}*`;
        message = encodeURIComponent(message);

        const url = `https://api.whatsapp.com/send/?phone=${res.data.contact}&text=${message}&type=phone_number&app_absent=0`;
        window.open(url);

        await StoreService.save({
          name: form.name,
          notes: form.note,
          dispatch_id: _dispatchType?.value,
          phone: `${form.code}${form.tlf}`,
          details: cart.map(item => {
            return {
              product_id: item.id,
              quantity: item.quantity
            }
          })
        });

        dispatch(actions.clearCart());
        onClose();
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const CartProduct = ({ product }) => {

    const enoughStock = (product) => {
      if (product.quantity > product.stock) {
        toast(`No hay stock suficiente (quedan ${product.stock})`, { icon: '⚠' });
        return false;
      }
      return true;
    }

    const onUpdate = (more = true) => {
      const newProd = { ...product };

      if (more) {
        newProd.quantity += 1;
      } else if (newProd.quantity > 1) {
        newProd.quantity -= 1;
      } else {
        return;
      }

      if (!enoughStock(newProd)) return;
      dispatch(actions.updateCartProduct(newProd));
    }

    const removeProduct = (product) => {
      dispatch(actions.removeFromCart(product));
    }

    return (
      <div className="flex flex-row justify-between items-center py-3 px-5 border-b border-b-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex-none lg:w-32 lg:h-32 w-20 h-20 rounded overflow-hidden">
            <img
              className="lg:w-32 lg:h-32 w-20 h-20 object-cover text-xs"
              src={process.env.REACT_APP_STORAGE + product.photo?.file}
              alt={product.name}
            />
          </div>

          <div className="mx-2 flex flex-col justify-between text-gray-800">
            <span className="flex max-h-10 overflow-hidden text-sm text-ellipsis">{product.name}</span>
            <span className="text-lg text-primary font-bold">${toAmount(product.sale_price)}</span>

            <div className="flex flex-row items-center text-white">
              <button type="button" className="rounded-md bg-primary p-1" onClick={() => onUpdate(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3">
                  <path fillRule="evenodd" d="M2.25 12a.75.75 0 01.75-.75h18a.75.75 0 010 1.5H3a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                </svg>
              </button>

              <span className="mx-4 text-primary">{product.quantity}</span>

              <button type="button" className="rounded-md bg-primary p-1" onClick={() => onUpdate()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3 h-3">
                  <path fillRule="evenodd" d="M12 2.25a.75.75 0 01.75.75v8.25H21a.75.75 0 010 1.5h-8.25V21a.75.75 0 01-1.5 0v-8.25H3a.75.75 0 010-1.5h8.25V3a.75.75 0 01.75-.75z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse items-center">
          <button type="button" className="text-red-600" onClick={() => removeProduct(product)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center pt-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="leading-6 p-6 pb-4 flex flex-row justify-between border-b">
                    <div className="flex flex-col">
                      <span className="text-primary text-lg font-medium">Resumen del pedido</span>
                      <span className="text-gray-700 text-sm font-normal">Producto</span>
                    </div>
                    <div onClick={() => onClose()} className="cursor-pointer">
                      <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </div>
                  </Dialog.Title>

                  <div>
                    {cart.map(product => (
                      <CartProduct
                        key={`cart-product-${product.id}`}
                        product={product}
                      />
                    ))}
                  </div>

                  <div className="flex flex-row justify-between px-10 py-2 bg-primary text-sm">
                    <span className="text-white">Total a Pagar</span>
                    <span className="text-secondary font-medium">${total}</span>
                  </div>

                  <div className="p-6">
                    <label htmlFor="name" className="block text-sm font-medium text-primary mb-1">Datos personales</label>
                    <input
                      id="name"
                      type="text"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary block w-full p-2.5"
                      placeholder="Nombre"
                      value={form.name}
                      onChange={e => setForm(s => ({ ...s, name: e.target.value, errorName: false }))}
                    />
                    {form.errorName && (
                      <span className="text-xs text-red-600">Debe indicar su nombre</span>
                    )}

                    <div className="flex flex-row mt-6">
                      <div className="relative inline-block">
                        <button
                          type="button"
                          onClick={() => setShowCodes(true)}
                          onBlur={() => setShowCodes(false)}
                          className="relative z-10 p-2.5 flex flex-row items-center text-gray-700 bg-gray border-l border-t border-b rounded-l-lg border-gray-300 focus:outline-none"
                        >
                          <span className="text-sm">{form.code}</span>
                          <svg className="w-5 h-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </button>

                        {showCodes && (
                          <div className="absolute border right-0 z-20 w-32 py-2 mt-2 left-0 bg-white rounded-lg shadow-xl">
                            <span
                              onClick={() => setForm(s => ({ ...s, code: '+58' }))}
                              className="block px-4 py-2 text-sm text-gray-600 capitalize transition-colors duration-200 transform cursor-pointer"
                            > +58 </span>
                          </div>
                        )}
                      </div>

                      <input
                        type="text"
                        className="bg-white border rounded-l-none border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary block w-full p-2.5"
                        placeholder="Número de celular"
                        pattern='/(^[0-9]+)/g'
                        maxLength={11}
                        value={form.tlf}
                        onChange={e => setForm(s => ({ ...s, tlf: (e.target.value || '').replace(/\D/g, ''), errorPhone: false }))}
                      />
                    </div>
                    {form.errorPhone && (
                      <span className="text-xs text-red-600">El número de teléfono debe tener al menos 10 dígitos</span>
                    )}

                    <label htmlFor="note" className="block text-sm font-medium text-primary mt-6 mb-1">Notas adicionales</label>
                    <textarea
                      id="note"
                      rows="3"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-secondary focus:border-secondary"
                      value={form.note}
                      onChange={e => setForm(s => ({ ...s, note: e.target.value }))}
                    ></textarea>

                    <label htmlFor="note" className="block text-sm font-medium text-primary mt-6 mb-1">Forma de despacho</label>
                    <div className="grid grid-cols-2 gap-4">
                      {dispatchTypes.map(x => (
                        <div key={`dispatch-${x.value}`} className="flex items-center mt-2">
                          <input
                            id={`radio-dispatch-${x.value}`}
                            type="radio"
                            checked={x.value === form.dispatch}
                            onClick={() => setForm(s => ({ ...s, dispatch: x.value, errorDispatch: false }))}
                            onChange={() => {}}
                            className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-transparent"
                          />
                          <label htmlFor={`radio-dispatch-${x.value}`} className="ml-2 text-sm font-medium text-gray-900">{x.label}</label>
                        </div>
                      ))}
                    </div>
                    {form.errorDispatch && (
                      <span className="text-xs text-red-600 mt-2">Debe seleccionar la forma de despacho</span>
                    )}
                  </div>

                  <div className="shadow-lg p-6">
                    <button
                      onClick={() => onSubmit()}
                      type="submit"
                      className={'w-full focus:outline-none py-2 px-4 rounded-full text-lg text-center bg-secondary'}
                    >
                      {loading
                      ? (
                        <div className="flex justify-center">
                          <div role="status">
                            <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-500 animate-spin fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Cargando...</span>
                          </div>
                        </div>
                      ) : (
                        <span className="text-primary text-md font-medium">Completar Pedido</span>
                      )}
                    </button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Checkout;